import { Dialog, Switch, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import AdminModalForm from "./AdminModalForm";

const AdminDoctorDetailsModal = ({ isOpen, closeModal, doctor, doctorId }) => {
  const [account, setAccount] = useState(doctor?.isActive);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  // const [sessionCost, setSessionCost] = useState(0); // initial value
  useEffect(() => {
    if (doctor) {
      setName(doctor.name || "");
      setEmail(doctor.email || "");
      setPhone(doctor.phone || "");
    }
  }, [doctor]);

  const handelUpdateAccount = async () => {
    await UpdateHooks(
      `https://servercareseebd.careseebd.com/api/v1/user/${doctorId}`,
      {
        isActive: !account,
      }
    );
    toast.success("Data updated !");
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="     lg:w-8/12 md:10/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle  alig shadow-xl transition-all">
                <div className="bg-gray-100 py-2 flex justify-between items-center px-5">
                  <h2 className="text-lg font-semibold">Doctor Details</h2>
                  <button
                    onClick={closeModal}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>
                <div className=" p-5">
                  <div className="grid grid-cols-2 gap-5 text-black">
                    <div className="">
                      <label className="text-sm">Name</label>
                      <input
                        className="border border-secondary/50 w-full py-1.5 px-3 rounded-md focus:outline-none mt-1"
                        type="text"
                        value={name}
                        // onChange={e => setName(e.target.value)}
                      />
                    </div>
                    <div className="">
                      <label className="text-sm">Email</label>
                      <input
                        className="border border-secondary/50 w-full py-1.5 px-3 rounded-md focus:outline-none mt-1"
                        type="email"
                        value={email}
                        // onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="">
                      <input
                        className="border border-secondary/50 w-full py-1.5 px-3 rounded-md focus:outline-none mt-1"
                        type="text"
                        value={phone}
                        // onChange={e => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="mt-2 flex items-center">
                      <Switch
                        checked={account}
                        onClick={handelUpdateAccount}
                        onChange={setAccount}
                        className={`${
                          account ? "bg-secondary/80" : "bg-secondary/30"
                        }
          relative inline-flex h-6 w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={`${
                            account ? "translate-x-6" : "translate-x-0"
                          }
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch>
                      <label
                        class="inline-block pl-2 text-lg font-semibold text-black"
                        for="flexSwitchCheckDefault"
                      >
                        Account
                      </label>
                    </div>
                  </div>

                  <AdminModalForm doctor={doctor} id={doctorId} />
                </div>
                <div className="border-t h-6 mt-5"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AdminDoctorDetailsModal;
