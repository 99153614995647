import { Icon } from "@iconify/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ClinicalFinding from "./ClinicalFinding";
import GeneralPatient from "./GeneralPatient";
import LicensePatient from "./LicensePatient";

const PatientEditProfile = () => {
  const { patientId } = useParams(); // Get the patientId from the URL
  const [patient, setPatient] = useState([]);
  const [packages, setPackages] = useState([]);

  const startDate = moment("2023-09-17");
  const endDate = moment("2023-09-10");

  const diffInDays = startDate.diff(endDate, "days");

  const [selectedDiv, setSelectedDiv] = useState(0); // Default to 0 for 'General'

  useEffect(() => {
    fetch(`https://servercareseebd.careseebd.com/api/v1/user/${patientId}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("careSeeAccessToken")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setPatient(data?.data));
  }, [patientId]);
  useEffect(() => {
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/package/specific?fieldName=${"userId"}&&fieldValue=${patientId}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length) {
          setPackages(data?.data?.[0]);
        }
      });
  }, [patientId]);

  return (
    <div className="bg-white p-5 mt-5">
      <div className="flex justify-start items-center">
        <button
          onClick={() => setSelectedDiv(0)}
          className={
            selectedDiv !== 0
              ? "rounded-l-md px-4 py-2 flex items-center justify-center font-semibold text-black bg-[#D3EEF9] duration-500"
              : "rounded-l-md px-4 py-2 flex items-center justify-center font-semibold bg-secondary text-white duration-500"
          }
        >
          <Icon className="" icon="healthicons:clinical-fe-outline" />
          <h1 className="ml-2">General</h1>
        </button>

        <button
          onClick={() => setSelectedDiv(2)} // Changed to 2 for 'License'
          className={
            selectedDiv !== 2 // Changed to 2
              ? " px-4 py-2 flex items-center justify-center font-semibold text-black bg-[#D3EEF9] duration-500"
              : " px-4 py-2 flex items-center justify-center font-semibold bg-secondary text-white duration-500"
          }
        >
          <Icon icon="icon-park-outline:general-branch" />{" "}
          {/* Change to the appropriate icon */}
          <h1 className="ml-2">License</h1>
        </button>
        <button
          onClick={() => setSelectedDiv(1)}
          className={
            selectedDiv !== 1
              ? "rounded-r-md px-4 py-2 flex items-center justify-center font-semibold text-black bg-[#D3EEF9] duration-500"
              : "rounded-r-md px-4 py-2 flex items-center justify-center font-semibold bg-secondary text-white duration-500"
          }
        >
          <Icon icon="icon-park-outline:general-branch" />
          <h1 className="ml-2 ">Clinical Finding</h1>
        </button>
      </div>

      {selectedDiv === 1 && <ClinicalFinding patientId={patientId} />}
      {selectedDiv === 0 && <GeneralPatient patientId={patientId} />}
      {selectedDiv === 2 && (
        <LicensePatient
          patientId={patientId}
          patient={patient}
          packages={packages}
        />
      )}
    </div>
  );
};

export default PatientEditProfile;
