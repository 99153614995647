import Brochure from "../Component/Brochure/Brochure";
import DeveloperTeam from "../Component/DeveloperTeam/DeveloperTeam";
import Faq from "../Component/FAQ/Faq";
import Lectures from "../Component/Lectures/Lectures";
import AuthHome from "../Component/LoginSingup/AuthHome";
import LoginSignUp from "../Component/LoginSingup/LoginSignUp";
import SignUp from "../Component/LoginSingup/SignUp";
import MoreResearches from "../Component/MoreResearhes/MoreResearches";
import Presentation from "../Component/Presentation/Presentation";
import Pricing from "../Component/Pricing/Pricing";
import PrivacyPolicy from "../Component/PrivacyTerms&Conditions/PrivacyPolicy";
import TermsComditions from "../Component/PrivacyTerms&Conditions/TermsComditions";
import Researchesbrochure from "../Component/ResearchesBrochure/Researchesbrochure";
import InsertOtp from "../Component/ResetPassword/InsertOtp";
import ResetPassword from "../Component/ResetPassword/ResetPassword";
import SetNewPassword from "../Component/ResetPassword/SetNewPassword";
import Team from "../Component/Team/Team";
import AboutUs from "../Pages/AboutUs/AboutUs";
import Accessories from "../Pages/Accessories/Accessories";
import Cart from "../Pages/Accessories/Cart";
import CheckOut from "../Pages/Accessories/CheckOut";
import ProductDetails from "../Pages/Accessories/ProductDetails";
import ContactUs from "../Pages/ContactUs/ContactUs";
import EyeTest from "../Pages/EyeTest/EyeTest";
import Home from "../Pages/Home/Home";
import PaymentCancel from "../Pages/PaymentCancel/PaymentCancel";
import PaymentFail from "../Pages/PaymentFail/PaymentFail";
import PaymentSuccess from "../Pages/PaymentSuccess/PaymentSuccess";

const PublicRoutes = [
  { path: "/", Component: Home },
  { path: "/home", Component: Home },

  { path: "/pricing", Component: Pricing },
  { path: "/aboutUs", Component: AboutUs },
  { path: "/eyeTest", Component: EyeTest },
  { path: "/team", Component: Team },
  { path: "/contactUs", Component: ContactUs },
  { path: "/faq", Component: Faq },
  { path: "/terms-condition", Component: TermsComditions },
  { path: "/privary-policy", Component: PrivacyPolicy },
  { path: "/presentation", Component: Presentation },
  { path: "/lectures", Component: Lectures },
  { path: "/researches-brochure", Component: Researchesbrochure },
  { path: "/brochure", Component: Brochure },
  { path: "/login", Component: AuthHome },
  { path: "/login/doctor", Component: LoginSignUp },
  { path: "/signUp", Component: SignUp },
  { path: "/resetPassword", Component: ResetPassword },
  { path: "/insert-token", Component: InsertOtp },
  { path: "/setNewPassword", Component: SetNewPassword },
  { path: "/moreResearches", Component: MoreResearches },
  { path: "/developer-team", Component: DeveloperTeam },
  { path: "/accessories", Component: Accessories },
  { path: "/accessories/checkout", Component: CheckOut },
  { path: "/accessories/cart", Component: Cart },

  { path: "/accessories/product-details/:id", Component: ProductDetails },

  { path: "/payment/success/:tran_id", Component: PaymentSuccess },
  { path: "/payment/fail/:tran_id", Component: PaymentFail },
  { path: "/payment/cancel/:tran_id", Component: PaymentCancel },
];

export default PublicRoutes;
