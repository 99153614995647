import { Switch } from "@headlessui/react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import AuthUser from "../../../Hooks/authUser";

const AdminModalForm = ({ id, doctor }) => {
  const { userInfo } = AuthUser();
  // console.log(id);
  const [oneDay, setOneDay] = useState(doctor?.oneDayToggle);
  const [oneMonth, setOneMonth] = useState(doctor?.oneMonthToggle);
  const [threeMonth, setThreeMonth] = useState(doctor?.threeMonthToggle);
  const [sixMonth, setSixMonth] = useState(doctor?.sixMonthToggle);

  const handelUpdate = async (e) => {
    e.preventDefault();
    const anaglyphCost = Number(e.target?.anaglyphCost?.value);
    const oneDay = Number(e.target?.oneDay?.value);
    const oneMonth = Number(e.target?.oneMonth?.value);
    const threeMonth = Number(e.target?.threeMonth?.value);
    const sixMonth = Number(e.target?.sixMonth?.value);
    await UpdateHooks(
      `https://servercareseebd.careseebd.com/api/v1/user/${doctor?._id}`,
      {
        anaglyphCost,
        oneDay,
        oneMonth,
        threeMonth,
        sixMonth,
      }
    );
    toast.success("data updated !");
  };

  const handelUpdateOneDay = async () => {
    await UpdateHooks(
      `https://servercareseebd.careseebd.com/api/v1/user/${doctor?._id}`,
      {
        oneDayToggle: !oneDay,
      }
    );
    toast.success("Data updated !");
  };
  const handelUpdateOneMonth = async () => {
    await UpdateHooks(
      `https://servercareseebd.careseebd.com/api/v1/user/${doctor?._id}`,
      {
        oneMonthToggle: !oneMonth,
      }
    );
    toast.success("Data updated !");
  };
  const handelUpdateThreeMonth = async () => {
    await UpdateHooks(
      `https://servercareseebd.careseebd.com/api/v1/user/${doctor?._id}`,
      {
        threeMonthToggle: !threeMonth,
      }
    );
    toast.success("Data updated !");
  };
  const handelUpdateSixMonth = async () => {
    await UpdateHooks(
      `https://servercareseebd.careseebd.com/api/v1/user/${doctor?._id}`,
      {
        sixMonthToggle: !sixMonth,
      }
    );
    toast.success("Data updated !");
  };

  return (
    <form onSubmit={handelUpdate}>
      <div className="my-5">
        <div class="relative  flex w-full items-stretch mt-5">
          <input
            type="number"
            name="anaglyphCost"
            class="relative m-0 -mr-0.5 block w-full  flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-1.5 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3]   focus:outline-none"
            // onChange={(e) => setAddAmount(e.target.value)}
            placeholder="Tk 0"
            defaultValue={doctor?.anaglyphCost ? doctor?.anaglyphCost : 0}
            aria-label="Search"
            aria-describedby="button-addon3"
          />

          <button
            class="relative z-[2] rounded-r border-2 border-secondary px-6 py-2 text-xs font-medium uppercase text-white transition duration-150 ease-in-out hover:bg-white bg-secondary hover:text-secondary whitespace-nowrap"
            type="submit"
          >
            Update Anaglyph Cost
          </button>
        </div>
      </div>

      <div className="grid grid-cols-3 ">
        <div className="mt-5 flex items-center">
          <Switch
            checked={oneDay}
            onClick={handelUpdateOneDay}
            onChange={setOneDay}
            className={`${oneDay ? "bg-secondary/80" : "bg-secondary/30"}
          relative inline-flex h-6 w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${oneDay ? "translate-x-6" : "translate-x-0"}
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
          <label
            class="inline-block pl-2 text-lg font-semibold text-black"
            for="flexSwitchCheckDefault"
          >
            1 Day
          </label>
        </div>
        <div class="relative  flex w-full items-stretch mt-5 col-span-2">
          <input
            type="number"
            name="oneDay"
            class="relative m-0 -mr-0.5 block w-full  flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-1.5 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3]   focus:outline-none"
            // onChange={(e) => setAddAmount(e.target.value)}
            placeholder="Tk 0"
            defaultValue={doctor?.oneDay ? doctor?.oneDay : 0}
            aria-label="Search"
            aria-describedby="button-addon3"
          />

          <button
            class="relative z-[2] rounded-r border-2 border-secondary md:px-6 px-2 py-2 text-xs font-medium uppercase text-white transition duration-150 ease-in-out hover:bg-white bg-secondary hover:text-secondary whitespace-nowrap"
            type="submit"
            id="button-addon3"
            data-te-ripple-init
          >
            Update 1 day Price
          </button>
        </div>

        <div className="mt-5 flex items-center">
          <Switch
            checked={oneMonth}
            onClick={handelUpdateOneMonth}
            onChange={setOneMonth}
            className={`${oneMonth ? "bg-secondary/80" : "bg-secondary/30"}
          relative inline-flex h-6 w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${oneMonth ? "translate-x-6" : "translate-x-0"}
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
          <label
            class="inline-block pl-2 text-lg font-semibold text-black"
            for="flexSwitchCheckDefault"
          >
            1 Month
          </label>
        </div>
        <div class="relative  flex w-full items-stretch mt-5 col-span-2">
          <input
            type="number"
            name="oneMonth"
            class="relative m-0 -mr-0.5 block w-full  flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-1.5 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3]   focus:outline-none"
            // onChange={(e) => setAddAmount(e.target.value)}
            placeholder="Tk 0"
            defaultValue={doctor?.oneMonth ? doctor?.oneMonth : 0}
            aria-label="Search"
            aria-describedby="button-addon3"
          />

          <button
            class="relative z-[2] rounded-r border-2 border-secondary md:px-6 px-2 py-2 text-xs font-medium uppercase text-white transition duration-150 ease-in-out hover:bg-white bg-secondary hover:text-secondary whitespace-nowrap"
            type="submit"
            id="button-addon3"
            data-te-ripple-init
          >
            Update 1 Month Price
          </button>
        </div>
        <div className="mt-5 flex items-center">
          <Switch
            checked={threeMonth}
            onClick={handelUpdateThreeMonth}
            onChange={setThreeMonth}
            className={`${threeMonth ? "bg-secondary/80" : "bg-secondary/30"}
          relative inline-flex h-6 w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${threeMonth ? "translate-x-6" : "translate-x-0"}
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
          <label
            class="inline-block pl-2 text-lg font-semibold text-black"
            for="flexSwitchCheckDefault"
          >
            3 Month
          </label>
        </div>
        <div class="relative  flex w-full items-stretch mt-5 col-span-2">
          <input
            type="number"
            name="threeMonth"
            class="relative m-0 -mr-0.5 block w-full  flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-1.5 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3]   focus:outline-none"
            // onChange={(e) => setAddAmount(e.target.value)}
            placeholder="Tk 0"
            defaultValue={doctor?.threeMonth ? doctor?.threeMonth : 0}
            aria-label="Search"
            aria-describedby="button-addon3"
          />

          <button
            class="relative z-[2] rounded-r border-2 border-secondary md:px-6 px-2 py-2 text-xs font-medium uppercase text-white transition duration-150 ease-in-out hover:bg-white bg-secondary hover:text-secondary whitespace-nowrap"
            type="submit"
            id="button-addon3"
            data-te-ripple-init
          >
            Update 3 Month Price
          </button>
        </div>
        <div className="mt-5 flex items-center">
          <Switch
            checked={sixMonth}
            onChange={setSixMonth}
            onClick={handelUpdateSixMonth}
            className={`${sixMonth ? "bg-secondary/80" : "bg-secondary/30"}
          relative inline-flex h-6 w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${sixMonth ? "translate-x-6" : "translate-x-0"}
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
          <label
            class="inline-block pl-2 text-lg font-semibold text-black"
            for="flexSwitchCheckDefault"
          >
            6 Month
          </label>
        </div>
        <div class="relative  flex w-full items-stretch mt-5 col-span-2">
          <input
            type="number"
            name="sixMonth"
            class="relative m-0 -mr-0.5 block w-full  flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-1.5 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3]   focus:outline-none"
            // onChange={(e) => setAddAmount(e.target.value)}
            placeholder="Tk 0"
            defaultValue={doctor?.sixMonth ? doctor?.sixMonth : 0}
            aria-label="Search"
            aria-describedby="button-addon3"
          />

          <button
            class="relative z-[2] rounded-r border-2 border-secondary md:px-6 px-2 py-2 text-xs font-medium uppercase text-white transition duration-150 ease-in-out hover:bg-white bg-secondary hover:text-secondary whitespace-nowrap"
            type="submit"
            id="button-addon3"
            data-te-ripple-init
          >
            Update 6 Month Price
          </button>
        </div>
      </div>
    </form>
  );
};

export default AdminModalForm;
