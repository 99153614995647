import React, { useEffect, useState } from "react";
import AuthUser from "../../../../Hooks/authUser";
import Loading from "../../../../Shared/Loading";
import DoctorTransactionsTable from "./DoctorTransactionsTable";

const DoctorTransactions = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo } = AuthUser();
  useEffect(() => {
    setLoading(true);
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/transaction/specific?fieldName=${"doctorId"}&&fieldValue=${
        userInfo?._id
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length) {
          setOrders(data?.data);
        }
        setLoading(false);
      });
  }, []);
  if (loading) {
    return <Loading />;
  }
  console.log(orders);
  return (
    <div className="w-full overflow-x-auto mt-10">
      <table
        className="w-full text-left rounded w-overflow-x-auto "
        cellspacing="0"
      >
        <tbody>
          <tr>
            <th
              scope="col"
              className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
            >
              Patient Name
            </th>
            <th
              scope="col"
              className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
            >
              Package
            </th>
            <th
              scope="col"
              className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
            >
              Amount
            </th>
            <th
              scope="col"
              className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
            >
              Email
            </th>
            <th
              scope="col"
              className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
            >
              Date
            </th>
            <th
              scope="col"
              className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
            >
              Transaction Id
            </th>
          </tr>
          {orders?.reverse()?.map((order) => (
            <DoctorTransactionsTable order={order} key={order?._id} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DoctorTransactions;
