import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import DeleteHook from "../../Hooks/DeleteHook";
import AdminDoctorDetailsModal from "../AdminDashboard/AdminDashboardDoctors/AdminDoctorDetailsModal";
import ResellerEditProfileModal from "./SuperAdminModal/ResellerEditProfileModal";

const SuperAdminAllDoctorTable = ({ doctorData, setRefetch, refetch }) => {
  const [selectedDoctorData, setSelectedDoctorData] = useState(null);
  const [modalIsOpenDoctorEditProfile, setModalIsOpenDoctorEditProfile] =
    useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  function closeModal() {
    setSelectedDoctor(null);
  }

  function openModal(doctor) {
    setSelectedDoctor(doctor);
  }

  const openModalDoctorEditProfile = (doctor) => {
    setSelectedDoctorData(doctor);
    setModalIsOpenDoctorEditProfile(true);
  };

  const closeModalDoctorEditProfile = () => {
    setModalIsOpenDoctorEditProfile(false);
  };
  return (
    <tr className="shadow">
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {doctorData.name}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {doctorData.email}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {doctorData.phone}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {doctorData.role}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        <div className="flex items-center gap-1">
          <div
            className={`h-2 w-2 rounded-full ${
              doctorData?.isActive ? "bg-green-600" : "bg-red-600"
            }`}
          ></div>{" "}
          {doctorData?.isActive ? "Active" : "Inactive"}
        </div>
      </td>
      <td className="h-16 px-6  transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center cursor-pointer">
        <div
          onClick={() => openModal(doctorData)}
          className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
        >
          <Icon icon="basil:edit-outline" />
        </div>
        <Link to={`super-admin-view-all-patient/${doctorData._id}`}>
          <div className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300">
            <Icon icon="grommet-icons:view" />
          </div>
        </Link>
        <button
          aria-label="Open delete confirmation"
          onClick={(e) => {
            DeleteHook({
              refetch,
              setRefetch,
              url: `https://servercareseebd.careseebd.com/api/v1/user/${doctorData?._id}`,
            });
          }}
          className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
        >
          <Icon icon="material-symbols:delete-outline" />
        </button>
      </td>

      {/* reuse this component. */}
      <ResellerEditProfileModal
        resellerData={selectedDoctorData}
        isOpen={modalIsOpenDoctorEditProfile}
        closeModal={closeModalDoctorEditProfile}
      ></ResellerEditProfileModal>

      {selectedDoctor && (
        <AdminDoctorDetailsModal
          closeModal={closeModal}
          isOpen={selectedDoctor !== null}
          doctor={selectedDoctor}
          doctorId={selectedDoctor._id}
        />
      )}
    </tr>
  );
};

export default SuperAdminAllDoctorTable;
