import React, { useEffect, useState } from "react";
import AuthUser from "../../../../Hooks/authUser";
import Loading from "../../../../Shared/Loading";
import DoctorOrdersTable from "./DoctorOrdersTable";

const DoctorOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo } = AuthUser();
  useEffect(() => {
    setLoading(true);
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/package/specific?fieldName=${"referralId"}&&fieldValue=${
        userInfo?._id
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length) {
          setOrders(data?.data);
        }
        setLoading(false);
      });
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="w-full overflow-x-auto mt-10">
      <table
        className="w-full text-left rounded w-overflow-x-auto "
        cellspacing="0"
      >
        <tbody>
          <tr>
            <th
              scope="col"
              className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
            >
              PACKAGE
            </th>
            <th
              scope="col"
              className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
            >
              AMOUNT
            </th>
            <th
              scope="col"
              className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
            >
              Email
            </th>
            <th
              scope="col"
              className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
            >
              ISSUE DATE
            </th>
            <th
              scope="col"
              className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
            >
              EXPIRE DATE
            </th>
          </tr>
          {orders?.reverse()?.map((order) => (
            <DoctorOrdersTable key={order?._id} order={order} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DoctorOrders;
