import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../Shared/Loading";
import AdminDoctorDetailsModal from "../AdminDashboard/AdminDashboardDoctors/AdminDoctorDetailsModal";

const SuperAdminView = () => {
  const { id } = useParams();
  const [DoctorData, setDoctorData] = useState([]);

  const [doctors, setDoctors] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selected, setSelected] = useState("");
  const [filterData, setFilterData] = useState([]);

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data of admin users associated with the specified ID
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/user/specific?fieldName=${"referralId"}&&fieldValue=${id}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("careSeeAccessToken")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setDoctors(data?.data);
        setFilterData(data?.data);
        setDoctorData(data.data); // Save the admin users data in state
      })
      .catch((error) => {
        console.error("Error fetching admin users data:", error);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`https://servercareseebd.careseebd.com/api/v1/user/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("careSeeAccessToken")}`,
        },
      })
      .then((response) => {
        // Adjust the setUserData call to consider the response structure
        setUserData(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [id]);

  // Current date formatting
  const date = new Date();
  const day = date.getDate();
  const year = date.getFullYear();
  const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][date.getMonth()];
  const formattedDate = `${day} ${monthName} ${year}`;

  const [selectedDoctor, setSelectedDoctor] = useState(null); // Changed from isOpen to selectedDoctor

  function closeModal() {
    setSelectedDoctor(null); // Close modal by setting selectedDoctor to null
  }

  function openModal(doctor) {
    setSelectedDoctor(doctor); // Open modal by setting selectedDoctor to the clicked doctor
  }

  const filter = doctors?.filter((user) => {
    const activity = user?.isActive ? "active" : "inActive";
    return (
      (searchInput &&
        user?.name?.toLowerCase()?.includes(searchInput?.toLowerCase())) ||
      (selected && activity === selected) ||
      (selected === "all" && (activity === "active" || "inActive"))
    );
  });

  useEffect(() => {
    setFilterData(filter);
  }, [searchInput, selected]);

  if (loading) {
    return <Loading></Loading>;
  }
  if (error) return <div>Error: {error}</div>;
  return (
    <div>
      <div>
        <div className="grid grid-cols-2 gap-10">
          <div>
            <h2 className="text-2xl">{userData.name} 's Patients</h2>
          </div>
          <div className="flex gap-5 justify-end text-sm md:text-base relative">
            <select
              onChange={(e) => setSelected(e.target.value)}
              className="border border-secondary px-3 rounded-lg"
              name="patientType"
            >
              <option value="all">All Patients</option>
              <option value="active">Active Patients</option>
              <option value="inActive">Inactive Patients</option>
            </select>
            <input
              onChange={(e) => setSearchInput(e.target.value)}
              className="border border-secondary rounded-lg px-3  py-2 focus:outline-none"
              type="search"
              name="search"
              placeholder="search "
            />
          </div>
        </div>

        <div className="w-full overflow-x-auto mt-10">
          <table
            className="w-full text-left rounded w-overflow-x-auto "
            cellspacing="0"
          >
            <tbody>
              <tr>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Phone
                </th>

                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Role
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Action
                </th>
              </tr>
              {filterData?.map((adminUser) => (
                <tr key={adminUser._id} className="shadow">
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {adminUser.name}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {adminUser.email}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {adminUser.phone}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {adminUser.role}
                  </td>
                  <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    {adminUser?.isActive ? (
                      <span className="bg-green-500  px-3 rounded-full text-white py-0.5">
                        Active
                      </span>
                    ) : (
                      <span className="bg-red-500  px-3 rounded-full text-white py-0.5">
                        Inactive
                      </span>
                    )}
                  </td>
                  <td className="h-16 px-6  transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center cursor-pointer">
                    <Link
                      to={`/superAdminDashboard/superadmin-doctor-view-patient/${adminUser._id}`} // Replaced 'dynamic' with the doctor's ID
                      className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
                    >
                      <Icon icon="ic:outline-auto-graph" />
                    </Link>
                    <div
                      onClick={() => openModal(adminUser)}
                      className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
                    >
                      <Icon icon="basil:edit-outline" />
                    </div>
                  </td>
                  {selectedDoctor && (
                    <AdminDoctorDetailsModal
                      closeModal={closeModal}
                      isOpen={selectedDoctor !== null}
                      doctor={selectedDoctor}
                      doctorId={selectedDoctor._id}
                    />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminView;
