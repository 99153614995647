import React, { useEffect, useState } from "react";
import SuperAdminAllResellerTable from "./SuperAdminAllResellerTable";

const SuperAdminAllRessellerTable = () => {
  const [reseller, setReseller] = useState([]);

  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await fetch(
          `https://servercareseebd.careseebd.com/api/v1/user/specific?fieldName=${"role"}&&fieldValue=${"admin"}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem(
                "careSeeAccessToken"
              )}`,
            },
          }
        );
        const data = await response.json();
        setReseller(data?.data);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, [refetch]);

  //cureent date
  const date = new Date();

  const day = date.getDate();
  const year = date.getFullYear();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = months[date.getMonth()];

  const formattedDate = `${day} ${monthName} ${year}`;
  return (
    <div className=" mt-3">
      <div>
        <h1 className=" text-xl">Recently Created Reseller Account</h1>
        <p className=" font-thin">{formattedDate}</p>
      </div>

      <div className="w-full overflow-x-auto mt-10">
        <table
          className="w-full text-left rounded w-overflow-x-auto "
          cellspacing="0"
        >
          <tbody>
            <tr>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Name
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Email
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Phone
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Role
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Status
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Action
              </th>
            </tr>

            {[...reseller]
              ?.reverse()
              ?.slice(0, 5)
              ?.map((resellerData) => (
                <SuperAdminAllResellerTable
                  key={resellerData?._id}
                  refetch={refetch}
                  setRefetch={setRefetch}
                  resellerData={resellerData}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SuperAdminAllRessellerTable;
