import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AuthUser from "../../Hooks/authUser";

const AdminDashboardNav = () => {
  const { userInfo } = AuthUser();
  const [doctors, setDoctors] = useState([]);
  const [patients, setPatients] = useState([]);
  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await fetch(
          `https://servercareseebd.careseebd.com/api/v1/user/specific?fieldName=${"role"}&&fieldValue=${"doctor"}&&fieldName2=${"referralId"}&&fieldValue2=${
            userInfo?._id
          }`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem(
                "careSeeAccessToken"
              )}`,
            },
          }
        );
        const data = await response.json();
        setDoctors(data?.data);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };

    fetchDoctors();
  }, [userInfo?._id]);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await fetch(
          `https://servercareseebd.careseebd.com/api/v1/user/specific?fieldName=${"role"}&&fieldValue=${"user"}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem(
                "careSeeAccessToken"
              )}`,
            },
          }
        );
        const data = await response.json();
        setPatients(data?.data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchPatients();
  }, []);

  let activeClass = {
    backgroundColor: "#291D89",
    color: "white",
  };
  const matchedPatients = patients.filter((patient) =>
    doctors.some((doctor) => doctor.email === patient?.referralEmail)
  );

  return (
    <div className="">
      <div className="flex gap-5 bg-white px-10 py-5 rounded-md shadow-md">
        <NavLink
          className="flex gap-2 items-center border border-secondary hover:bg-secondary hover:text-white py-1 md:px-4 px-2 rounded-md w-fit hover:scale-105 duration-500  shadow-lg shadow-secondary/50 text-sm md:text-base"
          style={({ isActive }) => (isActive ? activeClass : undefined)}
          to="overview"
        >
          {" "}
          <Icon icon="material-symbols:team-dashboard" /> <span>Dashboard</span>{" "}
        </NavLink>
        <NavLink
          className="flex gap-2 items-center border border-secondary hover:bg-secondary hover:text-white py-1 md:px-4 px-2 rounded-md  w-fit hover:scale-105 duration-500  shadow-lg shadow-secondary/50 text-sm md:text-base"
          style={({ isActive }) => (isActive ? activeClass : undefined)}
          to="doctors"
        >
          {" "}
          <Icon icon="fa6-solid:user-doctor" /> <span>Doctors</span>{" "}
        </NavLink>
      </div>

      <div className="bg-white px-10 py-5 rounded-md shadow-md my-5 flex gap-10">
        <div className=" flex justify-center items-center bg-secondary text-white shadow rounded-md gap-2 text-2xl w-fit p-10">
          <Icon icon="subway:admin-1"></Icon>
          <p>Total Doctors:</p>
          <p>{doctors?.length}</p>
        </div>
        <div className=" flex justify-center items-center bg-secondary text-white shadow rounded-md gap-2 text-2xl w-fit p-10">
          <Icon icon="subway:admin-1"></Icon>
          <p>Total Patients:</p>
          <p>{matchedPatients?.length}</p>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardNav;
