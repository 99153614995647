import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const CheckOut = () => {
  const [cartItems, setCartItems] = useState([]);
  const storedCartitem = localStorage.getItem("cart");
  const navigate = useNavigate();

  const [shippingCost, setShippingCost] = useState(0);
  const [taxRate, setTaxRate] = useState(0);

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(
        "https://servercareseebd.careseebd.com/api/v1/orderDetails/getOrderDetails"
      );

      if (response.data.status === "success" && response.data.data.length > 0) {
        const { shippingCost, taxRate } = response.data.data[0];
        setShippingCost(shippingCost);
        setTaxRate(taxRate);
      } else {
        console.error("Error fetching order details:", response.data);
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  useEffect(() => {
    if (storedCartitem) {
      const pasedCartItems = JSON.parse(storedCartitem);
      setCartItems(pasedCartItems);
    }
  }, []);

  const calculateSubtotal = () => {
    return cartItems.reduce(
      (total, product) => total + product.price * product.quantity,
      0
    );
  };

  const calculateShipping = () => {
    return shippingCost;
  };

  const calculateTax = () => {
    return (calculateSubtotal() * taxRate) / 100;
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const shipping = calculateShipping();
    const tax = calculateTax();
    return subtotal + shipping + tax;
  };

  const subtotal = calculateSubtotal();
  const shipping = calculateShipping();
  const tax = calculateTax();
  const total = calculateTotal();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    country: "",
    streetAddress: "",
    apartment: "",
    townCity: "",
    state: "",
    pinCode: "",
    phone: "",
    emailAddress: "",
    shippingMethod: "",
    orderNotes: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://servercareseebd.careseebd.com/api/v1/orders",
        {
          ...formData,
          cartItems: cartItems,
          subtotal,
          shipping,
          tax,
          total,
        }
      );

      toast.success("Order Submitted");
      localStorage.removeItem("cart");
      navigate(-2);
    } catch (error) {
      toast.error("Error Creating Order");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <form onSubmit={handleFormSubmit}>
        {/* Header */}
        <header className="bg-blue-600 p-6 text-white">
          <h1 className="text-3xl font-bold">Checkout</h1>
          <p className="text-lg mt-2">Complete your purchase</p>
        </header>

        {/* Main Content */}
        <main className="container mx-auto p-6 mt-8">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Billing Form */}
            <div className="lg:col-span-2 bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-semibold mb-6">Billing Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    First Name *
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder="First Name"
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Last Name *
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder="Last Name"
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Company Name (optional)
                  </label>
                  <input
                    type="text"
                    name="companyName"
                    placeholder="Company Name"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Country / Region *
                  </label>
                  <input
                    type="text"
                    name="country"
                    placeholder="Country"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Street Address *
                  </label>
                  <input
                    type="text"
                    placeholder="Street Address"
                    name="streetAddress"
                    value={formData.streetAddress}
                    onChange={handleInputChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Apartment, Suite, Unit, etc. (optional)
                  </label>
                  <input
                    type="text"
                    name="apartment"
                    placeholder="Apartment, Suite, Unit, etc."
                    value={formData.apartment}
                    onChange={handleInputChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Town / City *
                  </label>
                  <input
                    type="text"
                    name="townCity"
                    placeholder="Town / City"
                    value={formData.townCity}
                    onChange={handleInputChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    State *
                  </label>
                  <input
                    type="text"
                    name="state"
                    placeholder="State"
                    value={formData.state}
                    onChange={handleInputChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Zip Code *
                  </label>
                  <input
                    type="text"
                    name="pinCode"
                    placeholder="PIN Code"
                    value={formData.pinCode}
                    onChange={handleInputChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone *
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    placeholder="Phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Email Address *
                  </label>
                  <input
                    type="email"
                    name="emailAddress"
                    placeholder="Email Address"
                    value={formData.emailAddress}
                    onChange={handleInputChange}
                    required
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>

              {/* Shipping Method */}
              <div className="mt-6">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Shipping Method *
                </label>
                <select
                  name="shippingMethod"
                  value={formData.shippingMethod}
                  onChange={handleInputChange}
                  required
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="Flat Rate">Flat Rate</option>
                  <option value="Express Shipping">Express Shipping</option>
                </select>
              </div>

              {/* Order Notes */}
              <div className="mt-6">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Order Notes (optional)
                </label>
                <textarea
                  name="orderNotes"
                  value={formData.orderNotes}
                  onChange={handleInputChange}
                  rows="4"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Add any additional notes or comments about your order."
                />
              </div>
            </div>

            {/* Order Summary */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-2xl font-semibold mb-6">Your Order</h2>
              <div className="space-y-4">
                {cartItems.map((product) => (
                  <div key={product.name} className="flex justify-between">
                    <span className="text-gray-700">
                      {product.name.split(" ").slice(0, 1).join(" ")}...
                    </span>
                    <span className="text-gray-700">
                      {product.quantity} × ৳{product.price}
                    </span>
                  </div>
                ))}
              </div>
              <hr className="my-4 border-gray-300" />
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-700">Subtotal</span>
                  <span className="text-gray-700">৳{subtotal}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-700">Shipping</span>
                  <span className="text-gray-700">৳{shipping}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-700">Tax ({taxRate}%)</span>
                  <span className="text-gray-700">৳{tax}</span>
                </div>
              </div>
              <hr className="my-4 border-gray-300" />
              <div className="flex justify-between">
                <span className="text-lg font-semibold">Total</span>
                <span className="text-lg font-semibold text-blue-600">
                  ৳{total}
                </span>
              </div>
              <button
                type="submit"
                className="w-full mt-6 bg-blue-600 hover:bg-blue-700 text-white py-3 rounded-lg font-semibold transition-colors"
              >
                Place Order
              </button>
            </div>
          </div>
        </main>
      </form>
    </div>
  );
};

export default CheckOut;
