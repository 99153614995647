import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(storedCart);
  }, []);

  const removeFromCart = (productId) => {
    const updatedCart = cartItems.filter((item) => item._id !== productId);
    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const increaseQuantity = (productId) => {
    const updatedCart = cartItems.map((item) =>
      item._id === productId ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const decreaseQuantity = (productId) => {
    const updatedCart = cartItems
      .map((item) =>
        item._id === productId && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
      .filter((item) => item.quantity > 0);
    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const proceedToCheckout = () => {
    navigate("/accessories/checkout");
  };

  const totalItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const totalPrice = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return (
    <div className="bg-gray-50 min-h-screen p-4 sm:p-6">
      {/* Page Header with Professional Content */}
      <header className="text-center mb-6 sm:mb-10 px-2 sm:px-0">
        <h1 className="text-3xl font-bold text-gray-800">Your Shopping Cart</h1>
        <p className="text-gray-600 mt-2">
          Review your selected items before proceeding to checkout. Ensure you
          have everything you need for the best shopping experience.
        </p>
      </header>

      {cartItems.length === 0 ? (
        <p className="text-center text-gray-600">
          Your cart is currently empty. Browse our catalog to find amazing
          products.
        </p>
      ) : (
        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-4 sm:p-6">
          <ul className="space-y-4">
            {cartItems.map((item) => (
              <li
                key={item._id}
                className="flex flex-col sm:flex-row items-center justify-between p-4 border-b"
              >
                <img
                  src={item.imageUrl}
                  alt={item.name}
                  className="h-16 w-16 object-cover rounded mb-2 sm:mb-0"
                />
                <div className="flex-1 text-center sm:text-left sm:ml-4">
                  <h3 className="text-lg font-semibold text-gray-800">
                    {item.name}
                  </h3>
                  <p className="text-gray-600">৳ {item.price}</p>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2 mt-2 sm:mt-0">
                    <button
                      onClick={() => decreaseQuantity(item._id)}
                      className="bg-gray-300 px-2 py-1 rounded"
                    >
                      -
                    </button>
                    <span className="px-3 py-1 border rounded">
                      {item.quantity}
                    </span>
                    <button
                      onClick={() => increaseQuantity(item._id)}
                      className="bg-gray-300 px-2 py-1 rounded"
                    >
                      +
                    </button>
                  </div>
                  <button
                    onClick={() => removeFromCart(item._id)}
                    className="text-red-600 mt-2 sm:mt-0"
                  >
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>
          {/* Price Summary Overview */}
          <div className="mt-6 p-4 bg-gray-100 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              Order Summary
            </h3>
            <div className="flex justify-between text-gray-700">
              <p>Total Items:</p>
              <p>{totalItems}</p>
            </div>
            <div className="flex justify-between text-gray-700 mt-2">
              <p>Total Price:</p>
              <p className="font-semibold">৳ {totalPrice}</p>
            </div>
          </div>
          <div className="mt-6 text-center">
            <p className="text-gray-600">
              Secure payments and fast delivery guaranteed. Complete your
              purchase with confidence.
            </p>
            <button
              onClick={proceedToCheckout}
              className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 w-full sm:w-auto mt-4"
            >
              Proceed to Checkout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
