import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "./PaymentSuccess.css";

const PaymentSuccess = () => {
  const { tran_id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/package/specific?fieldName=${"tran_id"}&&fieldValue=${tran_id}`
    ).then((res) => res.json().then((data) => setData(data?.data[0])));
  }, [tran_id]);

  return (
    <div class="box min-h-screen">
      <div class="printer-top"></div>

      <div class="paper-container">
        <div class="printer-bottom"></div>

        <div class="paper">
          <div class="main-contents">
            <div class="success-icon">&#10004;</div>
            <div class="success-title">Payment Complete</div>
            <div class="success-description">
              <b>{data?.userName}</b> Your payment for{" "}
              <b className="text-primary">{data?.packagePrice}</b> in BDT has
              been received and send to careseebd
            </div>
            <div class="order-details">
              <div class="order-number-label">Transition id</div>
              <div class="order-number">{data?.tran_id}</div>
            </div>
            <div class="order-footer">You can close this page!</div>
          </div>
          <div class="jagged-edge"></div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
