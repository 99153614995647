import React from "react";
import { Link } from "react-router-dom";

const MoreResearchesCard = () => {
  return (
    <div>
      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          BUSTING THE OLD MYTHS AND PRACTICES
        </h2>
        <p className="text-sm text-center">
          Over the years amblyopia was thought to be only treatable if caught
          before age 6. This myth has been disproven. Current research shows
          that amblyopia can be treated even in adults we know is that there is
          “plasticity” in the human visual brain Over the years amblyopia was
          thought to be only treatable if caught before age 6
        </p>

        <Link
          to="/bustingTheOld"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">ANAGLYPHS</h2>
        <p className="text-sm text-center">
          Anaglyphs are filters that block out light from a portion of the
          target being viewed so that one part is visible by one eye and one
          part is visible by the other eye
        </p>

        <Link
          to="/anaglyphs"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          CareSee VISION THERAPY
        </h2>
        <p className="text-sm text-center">
          As an eye care patients, our role is to bridge the gap between
          science and society. Professionalism is the foundation of trust that
          the people in our country have for the doctors, we need to examine our
          responsibilities and know what works now and what are the technologies
          that can be used to provide better solutions to our patients so that
          he/she faces the least discomfort.
        </p>

        <Link
          to="/CareSeeVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          PATCHING VS VISION THERAPY
        </h2>
        <p className="text-sm text-center">
          Traditionally, placing a patch (occlusion or penalization) over the
          better eye was the widely used method to treat amblyopia.
          Theoretically, this treatment sounds good, as it strengthens the
          weaker eye without interference from the other eye.
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          PROBLEM AT THE TIME OF DRIVING DUE TO AMBLYOPIA
        </h2>
        <p className="text-sm text-center">
          You're driving. Something is coming toward you, you can't quite tell
          just how far away it is. Well that’s what happens most of the time if
          you have amblyopia as depth perception is severely affected. Depth
          perception occurs when your brain combines pictures from both eyes
          into one 3D image. Depth perception is impacted when one eye is blurry
          or the eyes aren’t aligned, which can also cause suppression or double
          vision.
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          TREATING LAZY EYE IN ADULTS
        </h2>
        <p className="text-sm text-center">
          Amblyopia is a disorder that can be very effectively treated at any
          age, despite the longstanding myth that these problems are treatable
          by the age of 7, Adults can succeed with vision therapy as well as
          children, due to neuro-plasticity. Neuroplasticity enables your brain
          to remain dynamic and flexible throughout your life.
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          WHY DIFFERENT EXERCISES , EVERYTIME I LOGIN?
        </h2>
        <p className="text-sm text-center">
          After your telecounseling, when your reports are reviewed the eye care
          expert decides the therapy schedule. When the reports are generated
          the artificial intelligence system selects the best suited exercise
          for patients' needs. Artificial intelligence system then categorise
          the exercise into various sets based on their performance. Everything
          is monitored by CareSee experts
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          VISION IS SO MUCH MORE THAN EYESIGHT!
        </h2>
        <p className="text-sm text-center">
          Vision is learned. A child learns to see just like he learns to walk
          and talk. When learning to walk and talk, he has the added opportunity
          of imitating his parents and siblings. In addition, parents can
          observe their children to determine if walking and talking are
          developing properly. Vision development, however, generally proceeds
          without much concerned awareness on the part of parents. Because of
          these differences in development, no two people see exactly alike.
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          VISION THERAPY FOR AMBLYOPIA
        </h2>
        <p className="text-sm text-center">
          CareSee is an artificial intelligence (AI) based software vision
          therapy program to treat amblyopia. It is a non-surgical treatment
          that acts like physical therapy to improve the coordination of the eye
          and brain. The goal of this therapy is to treat amblyopia because this
          cannot be treated with contact lenses, eyeglasses, and surgery. It
          helps the patient to achieve comfortable, clear binocular vision.
          Therapy can be done both online and offline as per your convenience
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          WELCOME TO CareSee 2.0
        </h2>
        <p className="text-sm text-center">
          The one and only software to have artificial intelligence (AI) based
          vision therapy exercises to provide accurate and individualized
          treatment for faster results. Redesigned games to provide better
          experience to patients by making it even more interactive, fun and
          engaging for the patients.
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          WHAT IS AMBLYOPIA
        </h2>
        <p className="text-sm text-center">
          Known as “lazy eyes”, is a condition in which the eye and brain don't
          work together as they should. People who have it will develop good
          vision in one eye and poor vision in the other. Amblyopia is one of
          the leading causes of vision loss amongst the young generation. In
          amblyopia People often get used to the vision problem as they see
          clearly with one eye, and might not mention it to parents. As a
          result, their amblyopia might not be diagnosed for months or even
          years. As the brain gets used to seeing only blurry images with the
          amblyopic eye even when best corrected with glasses or contact lenses.
          Left untreated, amblyopia can affect a child’s self-image, work,
          school, sports, friendships and may also lead to depression
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          CareSee VISION THERAPY- A BOON TO AMBLYOPIA
        </h2>
        <p className="text-sm text-center">
          CareSee has been a revolutionary software in Vision Therapy with its
          advanced technology and patient-first focussed vision. Lazy eye is no
          more a challenge.
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          ANTI-SUPRESSION THERAPY
        </h2>
        <p className="text-sm text-center">
          CareSee vision therapy glasses having red and blue filters allow the
          eye with red filter to see only red targets and with blue filter see
          only blue targets
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          COMBINATION OF MONOCULAR AND BINOCULAR EXERCISES
        </h2>
        <p className="text-sm text-center">
          A combination of Monocular & Binocular Exercises are available in
          CareSee Vision Therapy for the treatment of Amblyopia or Lazy eye at
          any age.
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          CROWDING PHENOMENON
        </h2>
        <p className="text-sm text-center">
          Crowding is a perceptual phenomenon where the recognition of objects
          presented away from the fovea is impaired by the presence of other
          neighboring objects
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          DICHOPTIC TREATMENT
        </h2>
        <p className="text-sm text-center">
          Dichoptic treatment of amblyopia (present in CareSee Vision Therapy)
          promotes binocular vision and reduces inhibitory interactions within
          the visual cortex.
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          GABOR PATCHES
        </h2>
        <p className="text-sm text-center">
          CareSee has Gabor patch exercises - a perceptual learning program
          based on visual stimulation and facilitates neural connection at the
          cortical level
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          MONOCULAR FIXATION IN BINOCULAR FIELD
        </h2>
        <p className="text-sm text-center">
          Monocular Fixation in Binocular Field (MFBF) in CareSee Vision
          Therapy, acts as a bridge from monocular to binocular activities
          improving sight in Amblyopia
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold text-center mb-5">
          RANDOM DOT STEREOGRAM
        </h2>
        <p className="text-sm text-center">
          Random Dot Stereogram (RDS) is used for therapeutic purpose also, the
          patient goes through these modules in CareSee Vision Therapy exercises
        </p>

        <Link
          to="/patchingVsVisionTherapy"
          className="text-white bg-secondary font-semibold py-2 px-5 rounded-full hover:scale-105 duration-300 block mx-auto w-fit mt-5 mb-10"
        >
          Read More..
        </Link>
      </div>
    </div>
  );
};

export default MoreResearchesCard;
