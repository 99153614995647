import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import Loading from "../../Shared/Loading";
import SuperAdminAllResellerTable from "./SuperAdminAllResellerTable";

const SuperAdminAllResellerList = () => {
  const [loading, setLoading] = useState(false);
  const [reseller, setReseller] = useState([]);
  const [filteredResellers, setFilteredResellers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const fetchDoctors = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://servercareseebd.careseebd.com/api/v1/user/specific?fieldName=${"role"}&&fieldValue=${"admin"}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem(
                "careSeeAccessToken"
              )}`,
            },
          }
        );
        const data = await response.json();

        setReseller(data?.data);
        setFilteredResellers(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching doctors:", error);
      }
    };
    fetchDoctors();
  }, [refetch]);

  const handleSearch = () => {
    const results = reseller.filter((resellerData) => {
      return (
        resellerData.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        resellerData.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        resellerData.phone === searchTerm
      );
    });
    setFilteredResellers(results);
  };

  // Current date formatting
  const date = new Date();
  const day = date.getDate();
  const year = date.getFullYear();
  const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][date.getMonth()];
  const formattedDate = `${day} ${monthName} ${year}`;
  // if(loading){
  //   return <Loading/>
  // }
  return (
    <div className=" mt-3">
      <div className="  lg:flex lg:justify-between">
        <div>
          <h1 className=" text-xl">Total Reseller Account</h1>
          <p className=" font-thin">{formattedDate}</p>
        </div>
        <div className="  w-full lg:max-w-xs flex justify-center items-center gap-2  text-xl text-black  rounded-md p-2">
          <p>Total Reseller:</p>
          <p className="font-bold">{reseller.length}</p>
        </div>
      </div>

      <div className="flex relative rounded-md w-full mt-3">
        <input
          type="text"
          placeholder="Enter Reseller Name, Email, or Phone"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
        />
        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-secondary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
        >
          <span>search</span>
          <span className="hidden md:block">
            <Icon icon="material-symbols:search"></Icon>
          </span>
        </button>
      </div>
      <div className="w-full overflow-x-auto mt-10">
        {loading ? (
          <Loading />
        ) : (
          <table
            className="w-full text-left rounded w-overflow-x-auto "
            cellspacing="0"
          >
            <tbody>
              <tr>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Phone
                </th>

                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Role
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                >
                  Action
                </th>
              </tr>
              {[...filteredResellers]?.reverse()?.map((resellerData) => (
                <SuperAdminAllResellerTable
                  key={resellerData?._id}
                  refetch={refetch}
                  setRefetch={setRefetch}
                  resellerData={resellerData}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SuperAdminAllResellerList;
