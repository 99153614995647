import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import AdminDoctorDetailsModal from "./AdminDoctorDetailsModal";
import { Link } from "react-router-dom";

const AdminAllDoctorsTable = ({ doctor }) => {
  const [selectedDoctor, setSelectedDoctor] = useState(null); 

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  function closeModal() {
    setSelectedDoctor(null); 
  }

  function openModal(doctor) {
    setSelectedDoctor(doctor); 
  }


  return (
    <>
      <tr className="shadow">
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {capitalizeFirstLetter(doctor.name)}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {doctor.email}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {capitalizeFirstLetter(doctor.role)}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          <div className="flex items-center gap-1">
            <div
              className={`h-2 w-2 rounded-full ${
                doctor?.isActive ? "bg-green-600" : "bg-red-600"
              }`}
            ></div>{" "}
            {doctor?.isActive ? "Active" : "Inactive"}
          </div>
        </td>
        <td className="h-16 px-6  transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center">
          <Link
            to={`/adminDashboard/doctorsPatient/${doctor._id}`} // Replaced 'dynamic' with the doctor's ID
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="ic:outline-auto-graph" />
          </Link>
          <button
            onClick={() => openModal(doctor)}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="basil:edit-outline" />
          </button>
        </td>
      </tr>

      {selectedDoctor && (
        <AdminDoctorDetailsModal
          closeModal={closeModal}
          isOpen={selectedDoctor !== null}
          doctor={selectedDoctor}
          doctorId={selectedDoctor._id}
        />
      )}
    </>
  );
};

export default AdminAllDoctorsTable;
