import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Product = () => {
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [addedToCart, setAddedToCart] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          "https://servercareseebd.careseebd.com/api/v1/products"
        );
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(cart);
    const cartMap = {};
    cart.forEach((item) => {
      cartMap[item._id] = item.quantity;
    });
    setAddedToCart(cartMap);
  }, []);

  const addToCart = (product) => {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItemIndex = cart.findIndex(
      (item) => item._id === product._id
    );

    if (existingItemIndex !== -1) {
      cart[existingItemIndex].quantity += 1;
    } else {
      cart.push({ ...product, quantity: 1 });
    }
    localStorage.setItem("cart", JSON.stringify(cart));
    setCartItems(cart);
    setAddedToCart((prev) => ({
      ...prev,
      [product._id]: cart[existingItemIndex]?.quantity || 1,
    }));
  };

  const removeFromCart = (productId) => {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    cart = cart.filter((item) => item._id !== productId);
    localStorage.setItem("cart", JSON.stringify(cart));
    setCartItems(cart);
    setAddedToCart((prev) => {
      const updated = { ...prev };
      delete updated[productId];
      return updated;
    });
  };

  return (
    <div className="bg-gray-50 min-h-screen p-6 font-sans">
      <div className="flex justify-end">
        <Link
          to="/accessories/cart"
          className="relative hover:opacity-80 transition-opacity justify-end"
        >
          <ShoppingCartIcon fontSize="large" className="text-gray-800" />
          {cartItems.length > 0 && (
            <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
              {cartItems.reduce((total, item) => total + item.quantity, 0)}
            </span>
          )}
        </Link>
      </div>
      {/* Page Header with Cart Icon */}
      <header className="flex justify-between items-center mb-10 max-w-7xl mx-auto">
        <div className="text-center w-full">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            Exclusive Products
          </h1>
          <p className="text-gray-600 text-lg">
            Find the best items just for you!
          </p>
        </div>
      </header>

      {/* Loading Indicator */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <CircularProgress />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 max-w-7xl mx-auto">
          {products.map((product) => (
            <div
              key={product._id}
              className="bg-white rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300 border border-gray-100 overflow-hidden"
            >
              <img
                src={product.imageUrl}
                alt={product.name}
                className="h-56 w-full object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold text-gray-800 mb-2 truncate">
                  {product.name}
                </h3>
                <p className="text-gray-600 text-lg font-medium mb-4">
                  ৳ {product.price}
                </p>
                <div className="flex justify-between items-center">
                  <Link
                    to={`/accessories/product-details/${product._id}`}
                    className="text-blue-600 hover:text-blue-700 text-sm font-medium transition-colors"
                  >
                    View Details
                  </Link>
                  {addedToCart[product._id] ? (
                    <button
                      onClick={() => removeFromCart(product._id)}
                      className="bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-red-600 transition-colors"
                    >
                      Remove ({addedToCart[product._id]})
                    </button>
                  ) : (
                    <button
                      onClick={() => addToCart(product)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-600 transition-colors"
                    >
                      Add to Cart
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Product;
