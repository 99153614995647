import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import SuperAdminAllDoctorTable from "./SuperAdminAllDoctorTable";

const SuperAdminAllDoctorList = () => {
  const [doctor, setDoctor] = useState([]);
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await fetch(
          `https://servercareseebd.careseebd.com/api/v1/user/specific?fieldName=${"role"}&&fieldValue=${"doctor"}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem(
                "careSeeAccessToken"
              )}`,
            },
          }
        );
        const data = await response.json();

        setDoctor(data?.data);
        setFilteredDoctors(data?.data);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };
    fetchDoctors();
  }, [refetch]);

  const handleSearch = () => {
    const results = doctor.filter((doctorData) => {
      return (
        doctorData.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doctorData.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (typeof doctorData.phone === "string" &&
          doctorData.phone.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
    setFilteredDoctors(results);
  };

  // Current date formatting
  const date = new Date();
  const day = date.getDate();
  const year = date.getFullYear();
  const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][date.getMonth()];
  const formattedDate = `${day} ${monthName} ${year}`;

  return (
    <div className=" mt-3">
      <div className="  lg:flex lg:justify-between">
        <div>
          <h1 className=" text-xl">Total Doctor Account</h1>
          <p className=" font-thin">{formattedDate}</p>
        </div>
        <div className="  w-full lg:max-w-xs flex justify-center items-center gap-2  text-xl  rounded-md p-2">
          <p>Total Doctor:</p>
          <p className="font-bold">{doctor.length}</p>
        </div>
      </div>

      <div className="flex relative rounded-md w-full mt-3">
        <input
          type="text"
          placeholder="Enter Doctor Name, Email, or Phone"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
        />
        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-secondary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
        >
          <span>search</span>
          <span className="hidden md:block">
            <Icon icon="material-symbols:search"></Icon>
          </span>
        </button>
      </div>

      <div className="w-full overflow-x-auto mt-10">
        <table
          className="w-full text-left rounded w-overflow-x-auto "
          cellspacing="0"
        >
          <tbody>
            <tr>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Name
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Email
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Phone
              </th>

              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Role
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Status
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Action
              </th>
            </tr>
            {[...filteredDoctors]?.reverse()?.map((doctorData) => (
              <SuperAdminAllDoctorTable
                setRefetch={setRefetch}
                doctorData={doctorData}
                refetch={refetch}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SuperAdminAllDoctorList;
