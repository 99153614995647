import React, { useState } from "react";
import { toast } from "react-toastify";
import countryCodes from "../../CountryCode/CountryCode.json";
import AuthUser from "../../Hooks/authUser";
const AdminDashboardIndex = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const { userInfo } = AuthUser();
  const [countryCode, setCountryCode] = useState(
    countryCodes[0]?.dial_code || ""
  );
  console.log(password);
  const createUser = async (user) => {
    try {
      const response = await fetch(
        "https://servercareseebd.careseebd.com/api/v1/user/signup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.message || "Failed to create Doctor.");
      }

      toast.success("Doctor created successfully!");
    } catch (error) {
      toast.error(error.message || "An error occurred.");
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const phoneWithCountryCode = `${countryCode}${phone}`;
    if (!password) {
      toast?.error("please provide a password");
    }
    const user = {
      name,
      email,
      password,
      phone: phoneWithCountryCode,
      confirmPassword: password,
      referralId: userInfo._id,
      referralName: userInfo.name,
      referralEmail: userInfo.email,
      referralPhone: userInfo.phone,
    };

    try {
      await createUser(user);
      e.target.reset(); // Reset the form
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  // generate password
  const generatePassword = (event) => {
    event.preventDefault();
    const chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let generatedPassword = "";
    for (let i = 0; i < 8; i++) {
      // Generates an 8-character password
      generatedPassword += chars[Math.floor(Math.random() * chars.length)];
    }
    setPassword(generatedPassword);
  };
  return (
    <div className="bg-white py-5 md:px-10 px-5 mt-10 rounded-lg">
      <div className="grid grid-cols-2 gap-10 items-center">
        <h2 className="text-lg font-semibold text-secondary">
          Add New Patientss
        </h2>
      </div>

      <div className="mt-5">
        <form
          onSubmit={handelSubmit}
          className="grid md:grid-cols-2 grid-cols-1 gap-5"
        >
          <input
            className="border border-secondary/50 w-full py-2 px-3 rounded-md focus:outline-none"
            type="text"
            name="name"
            required
            placeholder="Name"
          />
          <input
            className="border border-secondary/50 w-full py-2 px-3 rounded-md focus:outline-none"
            type="email"
            name="email"
            required
            placeholder="Email"
          />

          <div className="">
            <div className=" flex">
              <select
                className="border border-secondary/50 w-44 py-2 px-3 focus:outline-none mb-2"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.dial_code}>
                    {country.name} ({country.dial_code})
                  </option>
                ))}
              </select>
              <input
                className="border border-secondary/50 w-full py-2 px-3 focus:outline-none mb-2"
                type="number"
                name="phone"
                required
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="relative">
            <input
              type="text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Type or generate password"
              className="border border-secondary/50 p-2 w-full pl-24 pr-2 rounded-l-md"
            />
            <button
              onClick={generatePassword}
              className="absolute left-0 md:top-[42%] top-[50%] transform -translate-y-1/2 p-2 bg-secondary text-white hover:bg-secondary/90 rounded-l-md "
            >
              Generate
            </button>
          </div>
          <input
            className=" bg-secondary text-white py-2 px-3 rounded-md w-fit cursor-pointer mt-5 hover:scale-105 duration-300"
            type="submit"
            value="+ Add New Patientss"
          />
        </form>
      </div>
    </div>
  );
};

export default AdminDashboardIndex;
