import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import LecturesCard from "../Lectures/LecturesCard";
const PatientReview = () => {
  const [patientReview, setPatientReview] = useState([]);
  //   load data
  useEffect(() => {
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/patientReview/getpatientReview`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data.length) {
          setPatientReview(data?.data);
        }
      });
  }, []);
  return (
    <div className="bg-white">
      <div className=" lg:w-10/12 w-11/12 py-10 mx-auto min-h-screen ">
        <div>
          <h1 className="  text-secondary text-3xl font-bold text-center">
            Our Happy Patient
          </h1>
        </div>

        <Marquee pauseOnHover={true} autoFill={true} speed={30} gradient={true}>
          <div className="flex">
            {patientReview?.reverse()?.map((patientReview) => (
              <LecturesCard key={patientReview?.key} lecture={patientReview} />
            ))}
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default PatientReview;
