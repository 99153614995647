import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`https://servercareseebd.careseebd.com/api/v1/products/${id}`, {
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch product details");
        }
        return response.json();
      })
      .then((data) => {
        setProduct(data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          setError(error.message);
          setLoading(false);
        }
      });

    return () => controller.abort();
  }, [id]);

  if (loading) return <p className="text-center mt-10">Loading...</p>;
  if (error) return <p className="text-center mt-10 text-red-500">{error}</p>;

  return (
    <div className="min-h-screen">
      <header className="bg-blue-500 p-4 text-white">
        <h1 className="text-2xl font-semibold">Product Details</h1>
      </header>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 m-5">
        <div className="rounded-lg bg-gray-100 ">
          <img
            src={product.imageUrl}
            alt={product.name || "Product Image"}
            className="w-full h-auto object-cover rounded-lg"
          />
        </div>
        <div className="h-auto rounded-lg bg-gray-100 lg:col-span-2  p-5">
          <div className="bg-secondary w-auto p-5 rounded-md text-start text-white">
            <h1 className="text-lg font-bold"> {product?.name}</h1>
            <h1> ৳ {product?.price}</h1>
            <h1> Stock: {product?.stock}</h1>
            <h1> Weight: {product?.weight} g</h1>
            <h1> Dimensions: {product?.dimensions} cm</h1>
          </div>
          <div>
            <p className="text-justify mt-3">
              <strong>Description:</strong> {product?.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
