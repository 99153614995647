import React from "react";
import AdminDashboardHeader from "./AdminDashboardHeader";
import AdminDashboardNav from "./AdminDashboardNav";
import { Link, Outlet, useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";
import AuthUser from "../../Hooks/authUser";
import { useEffect } from "react";

const AdminDashboard = () => {
  const { userInfo, logout } = AuthUser();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (userInfo?.role === "admin") {
  //   } else {
  //     if (userInfo) {
  //       logout();
  //     }
  //     navigate("/login/doctor");
  //   }
  // }, []);

  return (
    <div>
      {/* dashboard header */}
      <div className="bg-white ">
        <div className="flex justify-between items-center py-2 lg:w-10/12 w-11/12 mx-auto min-h-[70px]">
          <Link to="/">
            <img className="w-[80%]" src={logo} alt="logo" />
          </Link>
          {/* <UserSideNav /> */}
          <div className="flex items-center gap-2">
            <div>
              <p className="text-lg font-semibold ">{userInfo?.name}</p>
              <p className="text-xs -mt-1 text-primary">Available</p>
            </div>

            <AdminDashboardHeader />
          </div>
        </div>
      </div>
      <div className="lg:w-10/12 w-11/12 mx-auto my-8">
        <div className="">
          <AdminDashboardNav />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default AdminDashboard;
