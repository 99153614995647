import React, { useEffect, useState } from "react";
import BrochureCard from "./BrochureCard";

const Brochure = () => {
  const [brochurees, setBrochurees] = useState([]);
  //   load data
  useEffect(() => {
    fetch(`https://servercareseebd.careseebd.com/api/v1/brochure/getbrochure`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data.length) {
          setBrochurees(data?.data);
        }
      });
  }, []);
  return (
    <div className="bg-white">
      <div className=" lg:w-10/12 w-11/12 py-10 mx-auto min-h-screen">
        <div className="mb-10">
          <div className="w-fit">
            <h1 className=" text-5xl text-secondary font-bold "> Brochure</h1>
            <div className="h-2 bg-secondary w-[55%] mt-3 rounded-full"></div>
          </div>
        </div>

        <div className="mt-5">
          {brochurees?.reverse()?.map((brochure) => (
            <BrochureCard key={brochure?._id} brochure={brochure} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Brochure;
