import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AdminDashboardIndex from "./Component/AdminDashboard/AdminDashboardIndex";
import DoctorDashboardIndex from "./Component/DoctorDashboard/DoctorDashboardIndex/DoctorDashboardIndex";
import SuperAdminDashboardIndex from "./Component/SuperAdminDashboard/SuperAdminDashboardIndex";
import UserDashboardIndex from "./Component/UserDashboard/UserDashboardIndex";
import AuthUser from "./Hooks/authUser";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import DoctorDashboard from "./Pages/DoctroDashbaord/DoctorDashboard";
import Main from "./Pages/Home/Main/Main";
import SuperAdminDashboard from "./Pages/SuperAdminDashboard/SuperAdminDashboard";
import UserDashboard from "./Pages/UserDashboard/UserDashboard";
import AdminRoutes from "./Routes/AdminRoutes";
import DoctorRoutes from "./Routes/DoctorRoutes";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoutes from "./Routes/PublicRoutes";
import SuperAdminRoute from "./Routes/SuperAdminRoute";
import UserRoutes from "./Routes/UserRoutes";
import Notfound from "./Shared/Notfound";

function App() {
  const [mutate, setMutate] = useState(false);
  const { userInfo, logout } = AuthUser();
  const [user, setUser] = useState([]);
  useEffect(() => {
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/user/${userInfo?._id}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("careSeeAccessToken")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setUser(data?.data));
  }, [userInfo?._id]);

  if (user?._id) {
    if (!user?.isActive && !mutate) {
      logout();
      toast.error("Please contact with doctor, to activate account");
      setMutate(true);
    }
  }
  return (
    <div className="App ">
      <Routes>
        <Route path="*" element={<Notfound />} />
        <Route
          path="/"
          element={
            <>
              <Main />
            </>
          }
        >
          {PublicRoutes.map(({ path, Component }, index) => (
            <Route key={index + 45} path={path} element={<Component />} />
          ))}
        </Route>

        <Route
          path="/adminDashboard"
          element={
            <PrivateRoute allowedRoles={["admin"]}>
              <AdminDashboard />
            </PrivateRoute>
          }
        >
          <Route index element={<AdminDashboardIndex />} />
          {AdminRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>

        <Route
          path="/superAdminDashboard"
          element={
            <PrivateRoute allowedRoles={["superAdmin"]}>
              <SuperAdminDashboard />
            </PrivateRoute>
          }
        >
          <Route index element={<SuperAdminDashboardIndex />} />
          {SuperAdminRoute.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>

        <Route
          path="/userDashboard"
          element={
            <PrivateRoute allowedRoles={["user"]}>
              <UserDashboard />
            </PrivateRoute>
          }
        >
          <Route index element={<UserDashboardIndex />} />
          {UserRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>

        <Route
          path="/doctorDashboard"
          element={
            <PrivateRoute allowedRoles={["doctor"]}>
              <DoctorDashboard />
            </PrivateRoute>
          }
        >
          <Route index element={<DoctorDashboardIndex />} />
          {DoctorRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
