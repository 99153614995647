import { Icon } from "@iconify/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const DoctorAllPatientsTable = ({ patient }) => {
  const date = moment(patient?.dateOfBirth).format("DD MMM YYYY");
  const [packages, setPackages] = useState([]);
  const issueDate = moment().format("YYYY-MM-DD");
  useEffect(() => {
    fetch(
      `https://servercareseebd.careseebd.com/api/v1/package/specific?fieldName=${"userId"}&&fieldValue=${
        patient?._id
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length) {
          setPackages(data?.data?.[0]);
        }
      });
  }, [patient?._id]);
  const isExpire = packages?.expireDate
    ? moment(issueDate).isAfter(packages?.expireDate)
    : true;

  return (
    <>
      <tr key={patient?._id} className="shadow">
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {patient?.name}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {patient?.email}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {isExpire ? (
            <span className="text-red-600 bg-red-100 px-3 rounded-full text-sm py-[2px]">
              Expire
            </span>
          ) : (
            <span className="text-green-600">{packages?.expireDate}</span>
          )}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          <div className="flex items-center gap-1">
            <div
              className={`h-2 w-2 rounded-full ${
                patient?.isActive ? "bg-green-600" : "bg-red-600"
              }`}
            ></div>{" "}
            {patient?.isActive ? "Active" : "Inactive"}
          </div>
        </td>

        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {date}
        </td>
        <td className="h-16 px-6  transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center">
          <Link
            to={`/doctorDashboard/PatientReport/${patient?._id}`} // Replaced 'dynamic' with the doctor's ID
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="ic:outline-auto-graph" />
          </Link>

          <Link to={`/doctorDashboard/patient-edit-profile/${patient?._id}`}>
            {" "}
            <button className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300">
              <Icon icon="basil:edit-outline" />
            </button>
          </Link>
        </td>
      </tr>
    </>
  );
};

export default DoctorAllPatientsTable;
