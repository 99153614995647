import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import DeleteHook from "../../Hooks/DeleteHook";
import ResellerEditProfileModal from "./SuperAdminModal/ResellerEditProfileModal";

const SuperAdminAllPatientTable = ({ resellerData, refetch, setRefetch }) => {
  const [selectedResellerData, setSelectedResellerData] = useState(null);
  const [modalIsOpenResellerEditProfile, setModalIsOpenResellerEditProfile] =
    useState(false);

  const openModalResellerEditProfile = (reseller) => {
    setSelectedResellerData(reseller);
    setModalIsOpenResellerEditProfile(true);
  };

  const closeModalResellerEditProfile = () => {
    setModalIsOpenResellerEditProfile(false);
  };

  return (
    <tr key={resellerData.id} className="shadow">
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {resellerData.name}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {resellerData.email}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {resellerData.phone}
      </td>
      <td className="whitespace-nowrap h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {resellerData.referralName}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {resellerData.role}
      </td>
      <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        <div className="flex items-center gap-1">
          <div
            className={`h-2 w-2 rounded-full ${
              resellerData?.isActive ? "bg-green-600" : "bg-red-600"
            }`}
          ></div>{" "}
          {resellerData?.isActive ? "Active" : "Inactive"}
        </div>
      </td>
      <td className="h-16 px-6  transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center cursor-pointer">
        <div
          onClick={() => openModalResellerEditProfile(resellerData)}
          className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
        >
          <Icon icon="basil:edit-outline" />
        </div>

        <button
          aria-label="Open delete confirmation"
          onClick={(e) => {
            DeleteHook({
              refetch,
              setRefetch,
              url: `https://servercareseebd.careseebd.com/api/v1/user/${resellerData?._id}`,
            });
          }}
          className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
        >
          <Icon icon="material-symbols:delete-outline" />
        </button>
      </td>

      <ResellerEditProfileModal
        resellerData={selectedResellerData}
        isOpen={modalIsOpenResellerEditProfile}
        closeModal={closeModalResellerEditProfile}
      ></ResellerEditProfileModal>
    </tr>
  );
};

export default SuperAdminAllPatientTable;
