import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router";
import { server_url } from "../../Config/API";
import moment from "moment/moment";
import AuthUser from "../../Hooks/authUser";
import UpdateHooks from "../../Hooks/UpdateHooks";
import PostHooks from "../../Hooks/PostHooks";
import hitSound from "../../Assets/final new sound/invalid-selection-39351.mp3";

const JumpAndRunGame = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const navigate = useNavigate();
  // Game state
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
const hit = new Audio(hitSound);

  // Refs for game elements
  const canvasRef = useRef(null);
  const requestIdRef = useRef(null);
  const startTimeRef = useRef(null);
  const obstaclesRef = useRef([]);
  const isInvulnerableRef = useRef(false);
  const flashTimerRef = useRef(0);
  const frameCountRef = useRef(0);

  // Game constants

  const CANVAS_WIDTH = window.innerWidth;
  const CANVAS_HEIGHT = window.innerHeight/2;

  // Player state
  const playerRef = useRef({
    x: CANVAS_WIDTH / 2,
    y: CANVAS_HEIGHT - 13,
    radius: 6 * 1.7,
    speedY: 0,
    gravity: 0.4,
    jumpForce: -10,
    isJumping: false,
  });

  // Ground settings
  const ground = {
    y: CANVAS_HEIGHT - 1,
    thickness: 1,
  };

  // Obstacle settings
  const obstacleSpeed = 4;
  const obstacleSpawnRate = 90;

  const [randomData, setRandomData] = useState([]);
  const { userInfo } = AuthUser();
  let date = moment().format("YYYY-MM-D");
  let time = moment().format("h:mm a");
  const previousStateRef = useRef(null);
  const scoreRef = useRef(0);
  const timeRef = useRef(300);
  const [displayScore, setDisplayScore] = useState(0);
  const [displayTime, setDisplayTime] = useState("5:00");
  // api

  const fetchFunction = async () => {
    await fetch(
      `${server_url}/randomObstacle/specific?fieldName=${"userId"}&&fieldValue=${
        userInfo?._id
      }&&fieldName2=${"date"}&&fieldValue2=${date}&&fieldName3=${"gameName"}&&fieldValue3=${"Random Obstacle Monocular"}`
    )
      .then((res) => res.json())
      .then((data) => {
        setRandomData(data?.data);
        if (data?.data.length) {
          scoreRef.current = data?.data[0]?.score;
          previousStateRef.current = data?.data;
        }
      });
  };

  useEffect(() => {
    if (randomData?.length) {
      timeRef.current = randomData[0]?.remainingTime;
      scoreRef.current = randomData[0]?.score;
    }
  }, [randomData]);

  useEffect(() => {
    fetchFunction();
    return () => {
      // insertFunction();
    };
  }, []);

  const insertFunction = useCallback(() => {
    if (previousStateRef?.current?.length) {
      UpdateHooks(
        `${server_url}/randomObstacle/updateRandomObstacle/${previousStateRef?.current[0]?._id}`,
        {
          date: date,
          time: time,
          score: scoreRef.current,
          remainingTime: timeRef.current,
        }
      );
    } else {
      PostHooks(
        `${server_url}/randomObstacle/addRandomObstacle`,
        {
          gameName: "Random Obstacle Monocular",
          userId: userInfo?._id,
          userName: userInfo?.name,
          date: date,
          time: time,
          score: scoreRef.current,
          remainingTime: timeRef.current,
        },
        "Random Obstacle score updated !"
      );
    }
  }, [randomData, timeRef, scoreRef]);


  // api integration end

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
let isSpacePressed = false;
    const handleKeyDown = (e) => {
      if (
        e.code === "Space" &&
        !isSpacePressed &&
        !playerRef.current.isJumping
      ) {
        playerRef.current.speedY = playerRef.current.jumpForce;
        playerRef.current.isJumping = true;
        isSpacePressed = true; // Mark space as pressed
      }
    };
    const handleKeyUp = (e) => {
      if (e.code === "Space") {
        isSpacePressed = false; // Reset flag when space is released
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Start game loop
    startTimeRef.current = Date.now();
    requestIdRef.current = requestAnimationFrame(gameLoop);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
      cancelAnimationFrame(requestIdRef.current);
    };
  }, []);

  // Update display states at intervals
  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayScore(scoreRef.current);
      const minutes = Math.floor(timeRef.current / 60);
      const seconds = timeRef.current % 60;
      setDisplayTime(`${minutes}:${seconds.toString().padStart(2, "0")}`);
    }, 100); // Update every 100ms
    return () => clearInterval(interval);
  }, []);

  // Countdown timer
  useEffect(() => {
    const interval = setInterval(() => {
      if (timeRef.current > 0) {
        timeRef.current = timeRef.current - 1;
      } else {
        insertFunction();
        navigate(-1); // Navigate to the previous route
        toggleFullScreen();
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timeRef.current]);

  const checkCollision = (circle, rect) => {
    const distX = Math.abs(circle.x - (rect.x + rect.width / 2));
    const distY = Math.abs(circle.y - (rect.y + rect.height / 2));

    if (distX > rect.width / 2 + circle.radius) return false;
    if (distY > rect.height / 2 + circle.radius) return false;

    if (distX <= rect.width / 2) return true;
    if (distY <= rect.height / 2) return true;

    const dx = distX - rect.width / 2;
    const dy = distY - rect.height / 2;
    return dx * dx + dy * dy <= circle.radius * circle.radius;
  };



  const update = () => {
    // updateTimer();

    if (isInvulnerableRef.current) {
      flashTimerRef.current++;
      if (flashTimerRef.current >= 60) {
        isInvulnerableRef.current = false;
        flashTimerRef.current = 0;
      }
    }

    const player = playerRef.current;
    if (player.isJumping) {
      player.speedY += player.gravity;
      player.y += player.speedY;

      if (player.y + player.radius > ground.y - ground.thickness) {
        player.y = ground.y - ground.thickness - player.radius;
        player.speedY = 0;
        player.isJumping = false;
      }
    }

    frameCountRef.current++;
    if (frameCountRef.current % obstacleSpawnRate === 0) {
      const width = 20 * 1.7;
      const height = (40 + Math.random() * 20) * 1.7;

      obstaclesRef.current.push({
        x: CANVAS_WIDTH,
        y: ground.y - height,
        width,
        height,
      });
    }

    obstaclesRef.current.forEach((obstacle, index) => {
      obstacle.x -= obstacleSpeed;

      if (obstacle.x + obstacle.width < 0) {
        obstaclesRef.current.splice(index, 1);
        scoreRef.current = scoreRef.current + 1;
      }

     if (!isInvulnerableRef.current && checkCollision(player, obstacle)) {
       if (scoreRef.current > 0) {
         scoreRef.current -= 2;

         // Restart the sound if it's already playing
         hit.currentTime = 0;
         hit.play();
       }

       isInvulnerableRef.current = true;

       setTimeout(() => {
         // Ensure player is no longer colliding before resetting
         if (!checkCollision(player, obstacle)) {
           isInvulnerableRef.current = false;
         }
       }, 1000);
     }
    });
  };

  const draw = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

    // Draw ground
    ctx.beginPath();
    ctx.moveTo(0, ground.y);
    ctx.lineTo(CANVAS_WIDTH, ground.y);
    ctx.strokeStyle = "black";
    ctx.lineWidth = ground.thickness;
    ctx.stroke();

    // Draw player
    const player = playerRef.current;
    ctx.beginPath();
    ctx.arc(player.x, player.y, player.radius, 0, Math.PI * 2);
    ctx.fillStyle =
      isInvulnerableRef.current && flashTimerRef.current % 10 < 5
        ? "red"
        : "black";
    ctx.fill();
    ctx.closePath();

    // Draw obstacles
    ctx.fillStyle = "black";
    obstaclesRef.current.forEach((obstacle) => {
      ctx.fillRect(obstacle.x, obstacle.y, obstacle.width, obstacle.height);
    });

    if (gameOver) {
      ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
      ctx.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

      ctx.fillStyle = "black";
      ctx.font = "24px monospace";
      ctx.textAlign = "center";
      ctx.fillText("Time's Up!", CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2);
      ctx.fillText(
        `Final Score: ${score}`,
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 2 + 30
      );
      ctx.font = "16px monospace";
      ctx.fillText(
        "Refresh to play again",
        CANVAS_WIDTH / 2,
        CANVAS_HEIGHT / 2 + 60
      );
    }
  };

  const gameLoop = () => {
    if (!gameOver) {
      update();
      draw();
      requestIdRef.current = requestAnimationFrame(gameLoop);
    }
  };

  useEffect(() => {
    toggleFullScreen();
  }, []);

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter full screen mode
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen().catch((error) => {
          console.error("Error entering full screen:", error);
        });
      }
    } else {
      // navigate(-1);
    }
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.keyCode === 27 && isFullScreen) {
        insertFunction();
        await navigate("/userDashboard/overview"); // Navigate to the previous route
        toggleFullScreen();
        // console.log("isFull");
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
      // console.log("return");
    };
  }, [isFullScreen, navigate]);
  return (
    <div tabIndex="0" className="w-full h-screen inset-0 fixed bg-[#f7f7f7]">
      <div className="flex space-x-5 text-xl mb-2 text-white justify-end p-4">
        <div className="bg-secondary px-4 py-2 rounded-lg">
          SCORE: {displayScore}
        </div>
        <div className="bg-secondary px-4 py-2 rounded-lg">
          TIME: {displayTime}
        </div>
      </div>
      <canvas
        ref={canvasRef}
        width={CANVAS_WIDTH}
        height={CANVAS_HEIGHT}
        className=" bg-[#f7f7f7]"
      />
    </div>
  );
};

export default JumpAndRunGame;
