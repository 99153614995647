import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteHook from "../../../Hooks/DeleteHook";
import PostHooks from "../../../Hooks/PostHooks";
import UpdateHooks from "../../../Hooks/UpdateHooks";

const SuperAdminPlans = () => {
  const [facilities, setFacilities] = useState([""]);
  const [refetch, setRefetch] = useState(false);
  const [plans, setPlans] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    price: 0,
    day: 0,
    facilities: [],
  });

  //   load data
  useEffect(() => {
    fetch(`https://servercareseebd.careseebd.com/api/v1/plan/getplan`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data.length) {
          setPlans(data?.data);
        }
      });
  }, [refetch]);

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  // update and post data
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formData?._id) {
        await UpdateHooks(
          `https://servercareseebd.careseebd.com/api/v1/plan/updatePlan/${formData?._id}`,
          { ...formData, facilities }
        );
        toast?.success(`Plan section Updated !`);
      } else {
        await PostHooks(
          `https://servercareseebd.careseebd.com/api/v1/plan/addPlan`,
          { ...formData, facilities },
          `Plan posted`
        );
        e.target.reset();

        setTimeout(() => {
          setRefetch(!refetch);
        }, 1000);
        setFormData({
          planrsName: "",
          videoUrl: "",
          videoTitle: "",
          videoDes: "",
        });
      }
    } catch (error) {
      toast?.success(error);
    }
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFacilities = (index, e) => {
    const newFacilities = [...facilities];
    newFacilities[index] = e.target.value;
    setFacilities(newFacilities);
  };

  const addInputField = () => {
    setFacilities([...facilities, ""]);
  };

  const removeInputField = (index) => {
    if (facilities.length < 2) {
      return toast.warning("You can't delete this field");
    }
    const newFacilities = [...facilities];
    newFacilities.splice(index, 1);
    setFacilities(newFacilities);
  };

  return (
    <div className="bg-white">
      <div className="w-fit  text-xl font-semibold mb-5">
        <h1>Update Plans Section</h1>
        <div className="h-1 mt-1 bg-secondary w-[40%]"></div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-50 p-6 rounded shadow-md w-full mx-auto"
      >
        <div className="mb-4"></div>

        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="name"
          >
            Plan Title
          </label>
          <input
            type="text"
            name="title"
            required
            onChange={handleChange}
            value={formData.title}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="price"
          >
            Total Days
          </label>
          <input
            type="number"
            name="day"
            required
            onChange={handleChange}
            value={formData?.day}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="price"
          >
            Price
          </label>
          <input
            type="number"
            name="price"
            required
            onChange={handleChange}
            value={formData?.price}
            className="w-full px-3 py-2 border rounded-md focus:outline-none"
          />
        </div>

        <div className="mb-4">
          <label
            className="block mb-2 text-sm font-medium text-gray-600"
            htmlFor="price"
          >
            Facilities
          </label>
          {facilities?.map((facility, index) => (
            <div key={index} className="flex items-center gap-2 mb-2">
              <input
                required
                type="text"
                name={`facility_${index}`}
                value={facility}
                onChange={(e) => handleFacilities(index, e)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none"
              />
              <div className="flex items-center gap-2">
                <button
                  type="button"
                  onClick={addInputField}
                  className="px-3 py-1 bg-secondary text-white rounded-md"
                >
                  +
                </button>
                <button
                  type="button"
                  onClick={(index) => removeInputField(index)}
                  className="px-3 py-1 bg-red-600 text-white rounded-md"
                >
                  x
                </button>
              </div>
            </div>
          ))}
        </div>

        <div>
          <button
            type="submit"
            className="bg-secondary text-white px-4 py-2 rounded hover:bg-secondary/90 hover:scale-105 duration-300"
          >
            Update plan !
          </button>
        </div>
      </form>

      <div className="w-fit  text-xl font-semibold my-5">
        <h1>All plan</h1>
        <div className="h-1 mt-1 bg-secondary w-[40%]"></div>
      </div>

      <div className=" my-10">
        <table
          className="w-full text-left rounded w-overflow-x-auto "
          cellspacing="0"
        >
          <thead>
            <tr className="text-center">
              <th className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Title
              </th>
              <th className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Price
              </th>
              <th className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Total Days
              </th>
              <th className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan) => (
              <tr className="border-b text-center" key={plan._id}>
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500 ">
                  {plan?.title}
                </td>
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  {plan?.price}
                </td>
                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  {plan?.day}
                </td>

                <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                  <div className="flex justify-center gap-3">
                    <button
                      className=" border border-red-500 bg-red-100 text-white px-1 py-1 rounded  text-2xl"
                      onClick={() =>
                        DeleteHook({
                          setRefetch,
                          refetch,
                          url: `https://servercareseebd.careseebd.com/api/v1/plan/deletePlan/${plan?._id}`,
                        })
                      }
                    >
                      <Icon icon="material-symbols:delete-outline" />
                    </button>
                    <button
                      onClick={() => {
                        setFormData(plan);
                        setFacilities(plan?.facilities);
                        scrollToTop();
                      }}
                      className="border border-secondary py-1 px-1 rounded-md hover:bg-secondary/10 duration-300 text-xl "
                    >
                      <Icon icon="basil:edit-outline" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SuperAdminPlans;
